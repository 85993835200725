<template>
  <div
    class="content"
    :class="{ fullpage : isFullpage }"
    :style="{backgroundImage:`url(${bgUrl})`,
      backgroundPosition: `${bgPosition}`,
      backgroundSize: `${bgSize}`,
      backgroundColor: `${bgColor}`,
      backgroundAttachment: `${bgAttachment}`}"
    >
    <b-container
      :class="{
        'content-container' : isContentContainer,
        'with-sidebar'      : withSidebar,
      }"
      v-if="isFluid"
      fluid
    >
      <slot></slot>
    </b-container>
    <b-container
      :class="{
        'content-container' : isContentContainer,
        'with-sidebar'      : withSidebar,
      }"
      v-else
    >
      <slot></slot>
    </b-container>
    <!-- <b-container
      :class="{ fullpage : isFullpage }"
      v-if="isFluid"
      fluid>
      <slot></slot>
    </b-container>
    <b-container :class="{ fullpage : isFullpage }" v-else>
      <slot></slot>
    </b-container> -->
    <modals-container :style="{zIndex: 100}" />
  </div>
</template>
<script>
  export default {
    props : {
      bgUrl : {
        type     : String,
        required : false,
      },
      bgColor : {
        type    : String,
        default : 'none',
      },
      bgPosition : {
        type    : String,
        default : 'center center',
      },
      bgSize : {
        type    : String,
        default : 'cover',
      },
      bgAttachment : {
        type    : String,
        default : 'scroll',
      },
      isFullpage : {
        type    : Boolean,
        default : false,
      },
      isFluid : {
        type    : Boolean,
        default : true,
      },
      isContentContainer : {
        type    : Boolean,
        default : false,
      },
      withSidebar : {
        type    : Boolean,
        default : false,
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .content {
    background-repeat: no-repeat;
  }

  p {
    color: #fff;
    font-family: 'ArialRoundedMTExtraBold';
    font-weight: bold;
    text-shadow: 0 0 3px $blue-text-stroke;
    -webkit-text-stroke: 1.5px $blue-text-stroke;
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (max-width: $xs-max) {
    .container-fluid, .container {
      padding-top: 4rem;
    }

    .with-sidebar {
      padding-top: 5rem;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .container-fluid, .container {
      padding-top: 5rem;
    }

    .with-sidebar {
      padding-top: 8rem;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .container-fluid, .container {
      padding-top: 6rem;
    }

    .with-sidebar {
      padding-top: 10rem;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .container-fluid, .container {
      padding-top: 6rem;
    }
    .with-sidebar {
      padding-top: 3rem;
    }
  }

  @media only screen and (min-width: $special) {
    .container-fluid, .container {
      padding-top: 6rem;
    }

    .with-sidebar {
      padding-top: 3rem;
    }

    .fullpage {
      min-height: calc(100vh - 6em);
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .container-fluid, .container {
      padding-top: 7.7rem;
    }

    .with-sidebar {
      padding-top: 3rem;
    }

    .fullpage {
      height: 100vh;
      border: 1px solid black;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .container-fluid, .container {
      padding-top: 10rem;
    }
    .with-sidebar {
      padding-top: 4rem;
    }
  }


  // .fullpage {
  //   display: flex;
  //   width: 100%;
  //   margin-top: $navbar-height;
  //   justify-content: center;
  //   flex-direction: column;
  //   background-position: center center;
  //   background-repeat: no-repeat;
  //   background-size: cover;

  //   // .container {
  //   //   max-width: 95rem !important;
  //   // }
  // }

</style>