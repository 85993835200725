var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",class:{ fullpage : _vm.isFullpage },style:({backgroundImage:`url(${_vm.bgUrl})`,
    backgroundPosition: `${_vm.bgPosition}`,
    backgroundSize: `${_vm.bgSize}`,
    backgroundColor: `${_vm.bgColor}`,
    backgroundAttachment: `${_vm.bgAttachment}`})},[(_vm.isFluid)?_c('b-container',{class:{
      'content-container' : _vm.isContentContainer,
      'with-sidebar'      : _vm.withSidebar,
    },attrs:{"fluid":""}},[_vm._t("default")],2):_c('b-container',{class:{
      'content-container' : _vm.isContentContainer,
      'with-sidebar'      : _vm.withSidebar,
    }},[_vm._t("default")],2),_c('modals-container',{style:({zIndex: 100})})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }